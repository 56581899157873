import React from "react"
import { Wrapper } from "../styles/main.styles"

import Memoji from "./memoji"

export default function Main() {
  return (
    <Wrapper>
      <div>
        <div>
          <h1>Hi I'm Yuval</h1>
          {/* <span>Web Developer</span> */}
          <div className="content">
            A self taught developer, currently working as a Frontend Developer
            at{" "}
            <a href="https://www.bylith.com/" target="_blank">
              Bylith
            </a>
            .
          </div>
        </div>
        <Memoji />
      </div>
    </Wrapper>
  )
}
