import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

export default function Memoji({ pic }) {
  return (
    <StaticImage
      src="../data/images/memoji.png"
      alt="memoji"
      placeholder="none"
      layout="constrained"
      quality={100}
      backgroundColor="transparent"
    />
  )
}
